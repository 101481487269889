function scrollAnchors(e, respond = null) {
    e.preventDefault();
    const distanceToTop = el => Math.floor(el.getBoundingClientRect().top);

    var targetID = (respond) ? respond.getAttribute('data-scrollnav') : this.getAttribute('data-scrollnav');
    const $target = document.querySelector(targetID);

    if (!$target) return;
    const originalTop = distanceToTop($target);
    window.scrollBy({
        top: originalTop,
        left: 0,
        behavior: 'smooth'
    });
    const checkIfDone = setInterval(function () {
        const atBottom = window.innerHeight + window.pageYOffset >= document.body.offsetHeight - 2;
        if (distanceToTop($target) === 0 || atBottom) {
            // $target.tabIndex = '-1';
            // $target.focus();
            // window.history.pushState('', '', targetID);
            clearInterval(checkIfDone);
        }
    }, 100);
} 

jQuery(document).ready(function ($) {

    var GSM = {
        menuOpen: false,
        Swiper: null,
        notification: false,
        scrollTimeout: null,
        events: {
            transitionEnd: [
                'transitionend,',
                'webkitTransitionEnd',
                'oTransitionEnd',
                'MSTransitionEnd'
            ]
        },
    };

    $('[data-mainnav]').on('click', function (e) {
        e.stopPropagation();
        e.preventDefault();

        GSM.menuOpen = !GSM.menuOpen;

        $('html, body').animate({
            scrollTop: 0
        }, 200, function () {
            $('body').toggleClass('menu-active', GSM.menuOpen);
        });
    });

    $('[data-scrollnav]').on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();

        scrollAnchors(e, this);

    });

    if ($('.swiper-container')) {

        GSM.Swiper = new Swiper('.swiper-container', {
            slidesPerView: 1.333,
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                renderBullet: function (index, className) {
                    return '<span class="' + className + '" data-slide-index="' + index + '"></span>';
                }
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            on: {
                init: function() {
                    this.slides[0].classList.add('is-visible');
                }
            }
        });

        GSM.Swiper.on('transitionEnd', function () {
            var i = GSM.Swiper.snapIndex;
            GSM.Swiper.slides[i].classList.add('is-visible');
        });

    }

    GSM.notification = sessionStorage.getItem('GSM__notification');
    
    if (!GSM.notification) {
        $('html').addClass('GSM__notification');
    } else {
        $('[data-notification]').remove();
    }

    $('[data-notification-close]').on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();

        var $notification = $(this).closest('[data-notification]');
        $('html').removeClass('GSM__notification');

        setTimeout(function() {
            $notification.remove();
        },1000);

        sessionStorage.setItem('GSM__notification', true);

    });

    function checkScrolltop() {
        var screenH = $(window).height();
        if ($(document).scrollTop() > screenH) {
            $('[data-scrolltop]').addClass('visible');
        } else {
            $('[data-scrolltop]').removeClass('visible');
        }
    }

    $('[data-scrolltop]').on('click', function(e) {
        e.preventDefault();
        e.stopPropagation();
        $('html, body').stop().animate({
            'scrollTop': 0
        }, 400, 'swing');
    });

    $(window).on('scroll', function() {
        if (!GSM.scrollTimeout) {
            GSM.scrollTimeout = setTimeout(function() {

                checkScrolltop();

                GSM.scrollTimeout = null;
            }, 50);
        }
    });

    checkScrolltop();

    // ---------------------------
});